import Home from '../pages/Home';
import LocalStorage from '../pages/localStorage/LocalStorage';
import Permutation from '../pages/permutation/Permutation';
import TempoCount from '../pages/tempoCount/TempoCount';

export type TPath = { path: string; label: string; compoent?: React.ReactElement };

const paths: TPath[] = [];

paths.push({ label: 'HOME', path: '/', compoent: <Home /> });
paths.push({ label: 'LocalStorage', path: '/localstorage', compoent: <LocalStorage /> });
paths.push({ label: 'Permutation', path: '/permutation', compoent: <Permutation /> });
paths.push({ label: 'TempoCount', path: '/tempoCount', compoent: <TempoCount /> });
paths.push({ label: 'Parking', path: 'https://Parking.yoojongwoo.com/' });
paths.push({ label: 'Flags', path: 'https://flags.yoojongwoo.com/' });

export default paths;
