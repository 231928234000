/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useEffect, useRef, useState } from 'react';

export default function Permutation() {
  const questionInput = useRef<HTMLInputElement>(null);
  const [questionArr, setQuestionArr] = useState<string[]>(['A', 'B', 'C']);
  const [answerSet, setAnswerSet] = useState<string[]>([]);

  const [traceText, setTraceText] = useState('');

  useEffect(() => {
    let _traceText = '';
    console.clear();

    // 순열 구하기

    console.log(`%c순열 구하기  ${questionArr}`, 'font-size:25px');
    _traceText += `순열 구하기  ${questionArr}`;

    const set = new Set();

    let depth = 0;

    const recursive = (made: string[], rest: string[]) => {
      let depthStr = '';
      for (let i = 0; i < depth; i++) {
        depthStr += '    ';
      }
      depth++;
      console.log(
        `%c${depthStr}call recursive : `,
        'color:red',
        `made : ${made.length > 0 ? made : 'empty'}  `,
        `rest : ${rest.length > 0 ? rest : 'empty'}`,
        depth
      );

      if (made.length > 0) set.add(made.join(''));

      rest.forEach((_yet, i) => {
        const newMade = [...made, rest[i]];
        const newRest = [...rest.slice(0, i), ...rest.slice(i + 1)];
        console.log(depthStr, 'i : ', i, ` newMade : ${newMade} newRest : ${newRest}`);
        recursive(newMade, newRest);
      });

      console.log(`%c${depthStr}END recursive : `, 'color:red;background:yellow', depth);
      depth--;
    };

    recursive([], questionArr);

    console.log('set : ', set);

    setAnswerSet(Array.from(set) as string[]);

    setTraceText(_traceText);
  }, [questionArr]);

  const onQChange = useCallback((e: any) => {
    console.log(e.target.value);

    const question = String(questionInput.current?.value).split('');

    setQuestionArr(question);
  }, []);

  return (
    <>
      <h2>순열을 구하는 방법 알아봅니다.</h2>
      <p>아래의 문자를 조합하여 조합 가능한 모든 경우의 수를 출력 합니다. </p>

      <input ref={questionInput} defaultValue={questionArr.join('')} />
      <button onClick={onQChange}>문제 변경</button>

      <p>{traceText}</p>

      <dl>
        <dt>answer</dt>
        <dd>
          <ul>
            {answerSet.map((item, i) => (
              <li key={i}>{item}</li>
            ))}
          </ul>
        </dd>
      </dl>
    </>
  );
}
