// export default function formatSizeUnits(bytes: number) {
//     let rtnStr = ''
//     if (bytes >= 1073741824) {
//         rtnStr = (bytes / 1073741824).toFixed(2) + ' GB'
//     } else if (bytes >= 1048576) {
//         rtnStr = (bytes / 1048576).toFixed(2) + ' MB'
//     } else if (bytes >= 1024) {
//         rtnStr = (bytes / 1024).toFixed(2) + ' KB'
//     } else if (bytes > 1) {
//         rtnStr = bytes + ' bytes'
//     } else if (bytes == 1) {
//         rtnStr = bytes + ' byte'
//     } else {
//         rtnStr = '0 bytes'
//     }
//     return rtnStr
// }

export default function formatSizeUnits(bytes: number, decimals = 2) {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}
