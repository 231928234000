import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import PATHS, { TPath } from '../path/Path';

export default function Header() {
  const loc = useLocation();

  const path = useMemo((): TPath | undefined => {
    return PATHS.find((item) => {
      return loc.pathname === item.path;
    });
  }, [loc.pathname]);

  return (
    <>
      <h1>{path?.label}</h1>
    </>
  );
}
