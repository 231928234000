import { useCallback, useMemo, useRef } from 'react';
import styles from './TempoCount.module.scss';

export default function TempoCount() {
  const refCount = useRef<any>();

  const count = useMemo(() => {
    const min = 0.7;
    const max = 1.5;

    const gap = 0.1;

    const secs: number[] = [];

    for (let sec = min; sec <= max; sec += gap) {
      secs.push(parseFloat(sec.toFixed(1)));
    }

    return secs;
  }, []);

  const startCount = useCallback((c: number) => {
    refCount.current = setInterval(() => {
      console.log('aa');
    }, c * 1000);
  }, []);

  const clear = useCallback(() => {
    if (refCount.current) {
      clearInterval(refCount.current);
      refCount.current = null;
    }
  }, []);

  console.log(count);

  return (
    <div>
      <ul>
        {count.map((c) => (
          <li className={styles.li}>
            <dl>
              <dt>{c}sec</dt>
              <dd>
                <button
                  onClick={() => {
                    startCount(c);
                  }}
                >
                  start
                </button>
              </dd>
            </dl>
          </li>
        ))}
      </ul>
      <button onClick={clear}>clear</button>
    </div>
  );
}
