import { Routes, Route, BrowserRouter } from 'react-router-dom';
import './App.css';
import Layout from './layout/Layout';
import './global.scss';
import PATHS from './path/Path';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route element={<Layout />}>
            {PATHS.map((item) => {
              return <Route key={item.path} path={item.path} element={item.compoent} />;
            })}
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
