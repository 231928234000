import { useCallback, useEffect, useState } from 'react';
import image from './image.jpg';

export default function useFileSave() {
  const [save, setSave] = useState('');
  const [targetSaveMem, setTargetSaveMem] = useState(26);

  const [saveCount, setSaveCount] = useState(0);

  const [base64string, setBase64String] = useState('');
  const [fileSize, setFileSize] = useState(0);

  useEffect(() => {
    console.log('useFileSave : ');

    fetch(image)
      .then((res) => {
        return res;
      })
      .then((response) => {
        console.log(response);
        return response.blob();
      })
      .then((blob) => {
        // console.log(blob)

        setFileSize(blob.size);
        var reader = new FileReader();
        reader.onload = function () {
          // console.log(this.result)

          setBase64String(this.result as string);
        }; // <--- `this.result` contains a base64 data URI
        reader.readAsDataURL(blob);
      });
  }, []);

  const doTest = useCallback(() => {
    console.log('testLimit : ');
    let saveStr = base64string;
    const key = 'abc';

    let saveCount = 0;
    while (true) {
      try {
        localStorage.clear();
        localStorage.setItem(key, saveStr);
        saveStr += base64string;
        saveCount++;

        // console.log(saveStr)

        const stored = localStorage.getItem(key);

        // console.log('stored : ', stored)
        console.log('saveCount : ', saveCount, Boolean(stored), String(stored).substring(0, 30));

        if (saveStr.length > targetSaveMem * 100000) break; // 2.5 mb https://developer.apple.com/forums/thread/715380

        setSaveCount(saveCount);
      } catch (error) {
        console.error(error);
        alert('테스트 완료 : ');
        break;
      }
    }

    setSave(saveStr);

    console.log(saveStr.length);

    console.log();
    console.log(new Blob([saveStr]).size);
  }, [base64string, targetSaveMem]);

  return { base64string, fileSize, targetSaveMem, setTargetSaveMem, saveCount, save, doTest };
}
