import { Outlet } from 'react-router-dom';
import Header from '../header/Header';
import Nav from '../nav/Nav';
import classNames from 'classnames';
import styles from './Layout.module.scss';

export default function Layout() {
  return (
    <>
      <Nav></Nav>
      <div className={classNames(styles.container)}>
        <Header></Header>
        <div className={classNames(styles.contents)}>
          <Outlet />
        </div>
      </div>
    </>
  );
}
