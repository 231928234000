import React, { ChangeEvent } from 'react';
import formatSizeUnits from '../../common/fileSize';
import useFileSave from './useFileSave';
import useIncreaseBytes from './useIncreaseBytes';
import useIncreaseBytesWithCheckStored from './useIncreaseBytesWithCheckStored';

const LocalStorage = () => {
  // 방법 1
  const { base64string, fileSize, targetSaveMem, setTargetSaveMem, saveCount, save, doTest: doTest1 } = useFileSave();

  const { refResult, doTest: doTest2 } = useIncreaseBytes();

  const { refResult: refResult3, doTest: doTest3 } = useIncreaseBytesWithCheckStored();

  return (
    <div>
      <h2>1. 아래의 용량을 가지고 있는 이미지를 용량 초과 에러가 나오기 전까지 저장 테스트</h2>
      <div>
        <button onClick={doTest1}> start test </button>
        <dl>
          <dt>이미지</dt>
          <dd>
            <img alt="저장 테스트 이미지" width="300" src={base64string} />
          </dd>

          <dt>base64string</dt>
          <dd>{base64string}</dd>
          <dt>fileSize</dt>
          <dd>
            {' '}
            {fileSize} : {formatSizeUnits(fileSize)}
          </dd>
        </dl>
        <h2>targetSaveMem</h2> :{' '}
        <input
          type="number"
          defaultValue={targetSaveMem}
          onChange={(e: ChangeEvent) => {
            setTargetSaveMem(parseInt((e.target as HTMLInputElement).value));
          }}
        />
        <h2>saveCount </h2> : {saveCount}
        <h2>saveSize (image)</h2> : {saveCount * fileSize} {formatSizeUnits(saveCount * fileSize)}
        <h2>text length</h2> : {save.length}
        <h2>TextEncoder encode size bytes</h2> : {new TextEncoder().encode(save).length}
        <h2>Blob size bytes</h2> : {new Blob([save]).size} / {formatSizeUnits(new Blob([save]).size)}
      </div>

      <hr />
      <h2>2. bytes 를 계속 늘려가면서 저장 할때 용량 초과 에러가 나올때까지 저장 시도</h2>
      <div>
        <button onClick={doTest2}> start test </button>
        <div ref={refResult}></div>
      </div>

      <hr />
      <h2>3. bytes 를 저장하고 저장이 되었는지를 시간 차를 두고 체크한후 저장이 잘 되었으면 용량을 늘려서 다시 저장 , 반복</h2>
      <div>
        <button onClick={doTest3}> start test </button>
        <div ref={refResult3}></div>
      </div>

      <div>
        <a target="blank" href="https://developer.apple.com/forums/thread/715380">
          https://developer.apple.com/forums/thread/715380
        </a>
      </div>
    </div>
  );
};
export default LocalStorage;
