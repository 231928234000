import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import PATHS from '../path/Path';
import styles from './Nav.module.scss';

export default function Nav() {
  return (
    <div className={classNames(styles.nav)}>
      {PATHS.map((item) => {
        if (item.compoent) {
          return (
            <p key={item.path}>
              <NavLink to={item.path} className={({ isActive }) => (isActive ? styles.on : '')}>
                {item.label}
              </NavLink>
            </p>
          );
        } else {
          return (
            <p key={item.path}>
              <a target="_blank" rel="noreferrer" href={item.path}>
                {item.label}
              </a>
            </p>
          );
        }
      })}
    </div>
  );
}
